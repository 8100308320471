import { Nav, Navbar, NavDropdown, Container, Alert }  from 'react-bootstrap';
import "../../App.css";

// Example destructuring
{/* <Header currentUser={currentUser}/> */}
// function Header({currentUser}) {

function Header(props) {

  return (
    <div className="Header">
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">HowIsMarket</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link href="#features">Features</Nav.Link> */}
            <Nav.Link href="/pricing">Pricing</Nav.Link>
            <Nav.Link href="/contactUs">Contacts</Nav.Link>

          </Nav>
          <Nav className="ml-auto">
            {props.currentUser != null ? <Nav.Link href="/user/profile"> profile <i className='fas fa-user'> </i></Nav.Link>
            :
            <Nav.Link href="/user/login"> Log In / Register <i className='fas fa-user'> </i></Nav.Link>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    { props.currentUser == null ? <Alert className='blink'> Register now and screen more than 500 value nse stocks </Alert> : null }
    

    </div>
  );
}

export default Header;