import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Nav } from 'react-bootstrap'
import { API_URL } from '../../constants'
import GPayPaymentBtn from '../payment/GPayPaymentBtn'

const Pricing = ({currentUser}) => {

  const [showQR, setShowQR] = useState(false)
  const [displayMsg, setDisplayMsg] =  useState(null)

  const onboardPaidUsers = async () =>{
    const token = localStorage.getItem("token")
    try {
      const response = await axios.post(`${API_URL}/onboardPaidUsers`,
        {
          data:{
            'changeUserType': 'premium'
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
  
      if (response.status == 202){

        localStorage.setItem("token", response.data.access_token)
        setDisplayMsg(response.data.message)
      }
      else if (response.status == 200) {
        setDisplayMsg(response.data.message)
      }

    } catch (error) {
      console.log(error)
      console.log(error.response.status)
      if (error.response.status == 401){
        setDisplayMsg("Please register or login (if already registered)...")
      }
      else {
        setDisplayMsg("Please register or login (if already registered)...")
      }
      
    }

  }

  useEffect(()=>{
    if(currentUser?.userType == 'basic') {
      setShowQR(true)
    }
  }, [currentUser])

  return (
    <div>
        <Alert style={{padding:'2rem'}}>
            {currentUser == null ? <>
            <h3>Premium subscription starts only @ 255/- per month !!!</h3>
            <Button size='lg' style={{margin:'2.5rem'}} onClick={onboardPaidUsers}> Subscribe Now </Button>
            
            {
              displayMsg ? <Alert variant='danger'>{displayMsg}</Alert>: null
            }
            </>
            :
            <>
            {/* {showQR ? <><img src="/GooglePay_QR.png" width="500" height="500"></img>
                        <form>
                        <Alert variant='alert'>after successfull payment submit your transaction ID</Alert>
                        <input type='number'></input>
                        <button type='submit'> Submit</button>
                        <Alert variant='alert'>your account will be activated with in 24 hours</Alert>
                        </form>
                      </>
            : null}
            {
              displayMsg ? <Alert variant='danger'>{displayMsg}</Alert>: null
            } */}
            <h3>Premium subscription starts only @ 1/- per month !!!</h3>
            <Nav.Link href="/user/payment"><Button size='lg' style={{margin:'2.5rem'}}> Subscribe Now </Button></Nav.Link>
            <GPayPaymentBtn/>
            </>
            }
            
            
        </Alert>
    </div>
  )
}

export default Pricing