import React, { useContext, useState } from 'react'
import axios from 'axios'
import { API_URL } from '../../constants'
import { Form, FloatingLabel, Button, Badge } from 'react-bootstrap'

const ContactUs = ({currentUser}) => {

  const [displayMsg, setDisplayMsg] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userFeedback, setUserFeedback] = useState(null);

  const sendFeedbackToServer = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(`${API_URL}/userFeedback`, {currentUser, userEmail, userFeedback});
      setDisplayMsg(response.data.message)
    }
    catch (error) {
      setDisplayMsg("Something went wrong, please try after some time.")
    }

  }

  return (
    <div className='contactUsForm' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

      <div style={{minWidth: '24rem', maxWidth: '80vw', minHeight: '24rem', marginTop: '5rem', padding: '1rem', border:'grey solid 0.05rem', boxShadow:'10px 10px 10px 10px lightgrey', backgroundImage: 'linear-gradient(to left, lightblue , lightgrey)', borderRadius: '0.5rem'}}>

        <Form onSubmit={sendFeedbackToServer}>

            <Badge style={{margin: '1rem'}} bg="primary">Write to Us</Badge>

            <FloatingLabel
              controlId="floatingInput"
              label="Email address"
              className="mb-3"
              >
              <Form.Control type="email" onChange={(e) => setUserEmail(e.target.value)} placeholder="" required/>
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingTextarea"
              label="Your message"
              className="mb-3"
              >
              <Form.Control style={{height: '10rem'}} as="textarea" rows={3} value={userFeedback} onChange={(e) =>setUserFeedback(e.target.value)} placeholder="" required />
            </FloatingLabel>

            <Button type='submit'>Submit</Button>

            {displayMsg && <div class="alert alert-dark" role="alert" style={{margin:'0.5rem'}}> {displayMsg}</div>}

        </Form>

      </div>
    </div>
  )
}

export default ContactUs